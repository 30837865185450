import { ApiResponse } from '../commonTypes';
import { BaseService } from './base.service';

class CompanyTripsService extends BaseService {
    private static _companyTripsService: CompanyTripsService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): CompanyTripsService {
        return this._companyTripsService || (this._companyTripsService = new this('companyTrips'));
    }

    public async getCompanyTrips(id: number, from: Date, to: Date): Promise<ApiResponse> {
        const url = `/${id}?from=${from.toISOString()}&to=${to.toISOString()}`;
        try {
            const axiosResponse = await this.$http.get(url);
            return { response: axiosResponse, data: axiosResponse.data };
        } catch (error: any) {
            throw error;
        }
    }
}

export const CompanyTripsApi = CompanyTripsService.Instance;

