import { CompanyEmployee } from '../types';
import { FETCH_EMPLOYEES_REQUEST, FETCH_EMPLOYEES_SUCCESS, FETCH_EMPLOYEES_FAILURE } from '../actions/companyActions';

interface CompanyEmployeesState {
    employees: CompanyEmployee[];
    loading: boolean;
    error: string | null;
}

const initialState: CompanyEmployeesState = {
    employees: [],
    loading: false,
    error: null
};

const companyEmployeesReducer = (state = initialState, action: any): CompanyEmployeesState => {
    switch (action.type) {
        case FETCH_EMPLOYEES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_EMPLOYEES_SUCCESS:
            return {
                ...state,
                employees: action.payload,
                loading: false,
                error: null
            };
        case FETCH_EMPLOYEES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

export default companyEmployeesReducer;
