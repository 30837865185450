import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../config/reducerConfig/rootReducer';
import { getCompanyUsers } from '../actions/companyActions';
import { deleteCompanyUser } from '../actions/companyUserActions'; 
import { AppButton, AreYouSureAppButton } from '../../../components';
import { DeleteIcon, PlusIcon } from '../../../components/Icon';

import { CompanyUser, Company } from '../types';
import { useDispatch } from 'react-redux';
import { AppBreadcrumbs, Spinner } from '../../../components';
import { useHistory } from 'react-router-dom';

interface CompanyUsersProps {
    companyId: number;
    company: Company;
    users?: CompanyUser[];
    usersLoading?: boolean;
    userError?: string | null;
}

export const CompanyUsersView: React.FC<CompanyUsersProps> = ({
    companyId,
    users = [],
    usersLoading,
    userError,
    company,
}) => {
    const dispatch = useDispatch();
    const [searchQuery, setSearchQuery] = useState('');
    const history = useHistory();

    useEffect(() => {
        dispatch(getCompanyUsers(companyId));
    }, [companyId, dispatch]);

    const filteredUsers = users.filter(
        (user) =>
            user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.email.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const handleAddUserClick = () => {
        history.push(`/company/adduser/${companyId}`);
    };

    const handleDeleteUser = (userId: any) => {
        dispatch(deleteCompanyUser(userId.userId));
    };

    return (
        <div className="main-content-item company-page">
            <AppBreadcrumbs pages={[{ name: 'Users /' }]} />

            <div className="companies-wrapper">
                <div className="app-booking-table-title">
                    <div style={{ fontWeight: 'bold', fontSize: 'larger' }}>Users</div>
                </div>
                <div style={{ marginBottom: '40px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <input
                        type="text"
                        placeholder="Search users..."
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                    />
                    <AppButton
                        size='medium'
                        designType="teal"
                        isIcon={true}
                        dataTestId="test-add-user"
                        onClick={handleAddUserClick}
                    >
                        <PlusIcon />
                    </AppButton>
                </div>
                <div className="company-list-wrapper">
                    {usersLoading ? (
                        <Spinner />
                    ) : (
                        <table className="company-list ui very basic table fixed">
                            <thead>
                                <tr className="company-table-header sticky" style={{ zIndex: 100, backgroundColor: '#ffffffeb' }}>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone Number</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredUsers.length === 0 ? (
                                    <tr>
                                        <td colSpan={4}>No users</td>
                                    </tr>
                                ) : (
                                    filteredUsers.map((user, index) => (
                                        <tr key={index} className="app-companies-company">
                                            <td>{`${user.firstName} ${user.lastName}`}</td>
                                            <td>{user.email}</td>
                                            <td>{user.phoneNumber}</td>
                                            <td>
                                                <div className="ui icon buttons">

                                                <AreYouSureAppButton
                                                    dataTestIdOk={"test-user-do-delete-" + user.companyUserId}
                                                    dataTestIdConfirm={'test-user-delete-confirm' + user.companyUserId}
                                                    mainBtndesignType="negative"
                                                    size='mini'
                                                    text={<><DeleteIcon /></>}
                                                    onClick={() => { handleDeleteUser({ userId: user.companyUserId as number }) }}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({});

export default connect(mapStateToProps)(CompanyUsersView);



