import React, { useState } from 'react';
import { AppBreadcrumbs } from '../../../components';
import { AddCompanyUser, Company } from '../types';
import { addCompanyUser } from '../actions/companyUserActions';
import { useDispatch } from 'react-redux';
import '../../../styles/company.css'
import { AppButton } from '../../../components';
import { SaveIcon } from '../../../components/Icon';


interface CompanyUserAddProps {
    companyId: number;
    company: Company
}

export const CompanyUserAddView: React.FC<CompanyUserAddProps> = ({ companyId, company }) => {
    const [formData, setFormData] = useState<AddCompanyUser>({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        password: '',
        passwordConfirmation: ''
    });
    const dispatch = useDispatch();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (formData.password.length < 8) {
            alert('Password must be at least 8 characters long.');
            return;
        }
        if (formData.password !== formData.passwordConfirmation) {
            alert('Password and password confirmation do not match.');
            return;
        }
        if (!validateEmail(formData.email)) {
            alert('Invalid email address.');
            return;
        }
        dispatch(addCompanyUser(companyId, formData));
    };

    const validateEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    return (
        <div className="main-content-item company-page">
            <AppBreadcrumbs pages={[{ name: 'Company Add User /' }]} />

            <div className="companies-wrapper">
                <div style={{ marginBottom: '40px' }}></div>

                <div className="app-company-table-title">
                    <div style={{ fontWeight: 'bold', fontSize: 'larger' }}>Add an admin user to {company.companyName}</div>
                </div>

                <div className="company-list-wrapper">
                    <form onSubmit={handleSubmit}>
                        <div className="company-form-group">
                            <label className="company-form-label">First Name:</label>
                            <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} className="company-form-input" required />
                        </div>
                        <div className="company-form-group">
                            <label className="company-form-label">Last Name:</label>
                            <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} className="company-form-input" required />
                        </div>
                        <div className="company-form-group">
                            <label className="company-form-label">Phone:</label>
                            <input type="text" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} className="company-form-input" required />
                        </div>
                        <div className="company-form-group">
                            <label className="company-form-label">Email:</label>
                            <input type="email" name="email" value={formData.email} onChange={handleChange} className="company-form-input" required />
                        </div>
                        <div className="company-form-group">
                            <label className="company-form-label">Password:</label>
                            <input type="password" name="password" value={formData.password} onChange={handleChange} className="company-form-input" minLength={8} required />
                        </div>
                        <div className="company-form-group">
                            <label className="company-form-label">Password confirm:</label>
                            <input type="password" name="passwordConfirmation" value={formData.passwordConfirmation} onChange={handleChange} className="company-form-input" minLength={8} required />
                        </div>
                        <AppButton
                            size='medium'
                            designType="teal"
                            isIcon={true}
                            loading={false}
                            disabled={false}
                            dataTestId="test-add-company"
                            type='submit'
                        >
                            <> {'Lagre '}<SaveIcon /></>
                        </AppButton>

                    </form>
                </div>
            </div>
        </div>
    );
};

export default CompanyUserAddView;

