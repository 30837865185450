import React, { useState } from 'react';
import { AppBreadcrumbs } from '../../../components';
import { Company } from '../types';
import { addCompany } from '../actions/companyActions';
import { useDispatch } from 'react-redux';
import { AppButton } from '../../../components';
import { SaveIcon } from '../../../components/Icon';
import '../../../styles/company.css';

interface CompanyAddProps { }

export const CompanyAddView: React.FC<CompanyAddProps> = () => {
    const [formData, setFormData] = useState<Company>({
        companyName: '',
        companyPhoneNumber: '',
        companyEmail: '',
        representativeName: '',
        companyNo: '',
        zip: '',
        city: '',
        representativeEmail: '',
        representativePhoneNumber: '',
        address: ''
    });
    const dispatch = useDispatch();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        dispatch(addCompany(formData));
    };

    return (
        <div className="main-content-item company-page">
            <AppBreadcrumbs pages={[{ name: 'Company Details /' }]} />

            <div className="companies-wrapper">
                <div style={{ marginBottom: '40px' }}></div>

                <div className="app-company-table-title">
                    <div style={{ fontWeight: 'bold', fontSize: 'larger' }}>Company Details</div>
                </div>

                <div className="company-list-wrapper">
                    <form onSubmit={handleSubmit}>
                        <div className="company-form-group">
                            <label className="company-form-label">Name:</label>
                            <input type="text" name="companyName" value={formData.companyName} onChange={handleChange} className="company-form-input" required />
                        </div>
                        <div className="company-form-group">
                            <label className="company-form-label">Address:</label>
                            <input type="text" name="address" value={formData.address} onChange={handleChange} className="company-form-input" required />
                        </div>
                        <div className="company-form-group">
                            <label className="company-form-label">Phone:</label>
                            <input type="text" name="companyPhoneNumber" value={formData.companyPhoneNumber} onChange={handleChange} className="company-form-input" required />
                        </div>
                        <div className="company-form-group">
                            <label className="company-form-label">Email:</label>
                            <input type="text" name="companyEmail" value={formData.companyEmail} onChange={handleChange} className="company-form-input" required />
                        </div>
                        <div className="company-form-group">
                            <label className="company-form-label">Representative Name:</label>
                            <input type="text" name="representativeName" value={formData.representativeName} onChange={handleChange} className="company-form-input" required />
                        </div>
                        <div className="company-form-group">
                            <label className="company-form-label">Company No:</label>
                            <input type="text" name="companyNo" value={formData.companyNo} onChange={handleChange} className="company-form-input" required />
                        </div>
                        <div className="company-form-group">
                            <label className="company-form-label">Zip:</label>
                            <input type="text" name="zip" value={formData.zip} onChange={handleChange} className="company-form-input" required />
                        </div>
                        <div className="company-form-group">
                            <label className="company-form-label">City:</label>
                            <input type="text" name="city" value={formData.city} onChange={handleChange} className="company-form-input" required />
                        </div>
                        <div className="company-form-group">
                            <label className="company-form-label">Representative Email:</label>
                            <input type="text" name="representativeEmail" value={formData.representativeEmail} onChange={handleChange} className="company-form-input" required />
                        </div>
                        <div className="company-form-group">
                            <label className="company-form-label">Representative Phone Number:</label>
                            <input type="text" name="representativePhoneNumber" value={formData.representativePhoneNumber} onChange={handleChange} className="company-form-input" required />
                        </div>
                        <AppButton
                            size='medium'
                            designType="teal"
                            isIcon={true}
                            loading={false}
                            disabled={false}
                            dataTestId="test-add-company"
                            type="submit"
                            
                        >
                            <> {'Lagre '}<SaveIcon /></>
                        </AppButton>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CompanyAddView;
