import { CompanyEmployeeApi } from "../../../api/companyEmployee.service";
import { CompanyEmployee } from '../types';

// Action types
export const ADD_COMPANY_EMPLOYEE_REQUEST = 'ADD_COMPANY_EMPLOYEE_REQUEST';
export const ADD_COMPANY_EMPLOYEE_SUCCESS = 'ADD_COMPANY_EMPLOYEE_SUCCESS';
export const ADD_COMPANY_EMPLOYEE_FAILURE = 'ADD_COMPANY_EMPLOYEE_FAILURE';

export const DELETE_COMPANY_EMPLOYEE_REQUEST = 'DELETE_COMPANY_EMPLOYEE_REQUEST';
export const DELETE_COMPANY_EMPLOYEE_SUCCESS = 'DELETE_COMPANY_EMPLOYEE_SUCCESS';
export const DELETE_COMPANY_EMPLOYEE_FAILURE = 'DELETE_COMPANY_EMPLOYEE_FAILURE';

// Action creators
const addCompanyEmployeeRequest = () => ({
    type: ADD_COMPANY_EMPLOYEE_REQUEST
});

const addCompanyEmployeeSuccess = (companyEmployee: CompanyEmployee) => ({
    type: ADD_COMPANY_EMPLOYEE_SUCCESS,
    payload: companyEmployee
});

const addCompanyEmployeeFailure = (error: string) => ({
    type: ADD_COMPANY_EMPLOYEE_FAILURE,
    payload: error
});

const deleteCompanyEmployeeRequest = () => ({
    type: DELETE_COMPANY_EMPLOYEE_REQUEST
});

const deleteCompanyEmployeeSuccess = (employeeId: number) => ({
    type: DELETE_COMPANY_EMPLOYEE_SUCCESS,
    payload: employeeId
});

const deleteCompanyEmployeeFailure = (error: string) => ({
    type: DELETE_COMPANY_EMPLOYEE_FAILURE,
    payload: error
});

export const addCompanyEmployee = (company: number, companyEmployee: CompanyEmployee) => {
    return async (dispatch: any, getState: any) => {
        dispatch(addCompanyEmployeeRequest());
        try {
            companyEmployee.companyId = company;
            const result = await CompanyEmployeeApi.addCompanyEmployee(companyEmployee);

            if (!result) {
                dispatch(addCompanyEmployeeFailure('Failed to add company employee'));
                return;
            }

            const addedCompanyEmployee = result.data;
            dispatch(addCompanyEmployeeSuccess(addedCompanyEmployee));
        } catch (error: any) {
            dispatch(addCompanyEmployeeFailure(error.message));
        }
    };
};

export const deleteCompanyEmployee = (employeeId: number) => {
    return async (dispatch: any, getState: any) => {
        dispatch(deleteCompanyEmployeeRequest());
        try {
            const result = await CompanyEmployeeApi.removeCompanyEmployee(employeeId);

            if (!result) {
                dispatch(deleteCompanyEmployeeFailure('Failed to delete company employee'));
                return;
            }

            dispatch(deleteCompanyEmployeeSuccess(employeeId));
        } catch (error: any) {
            dispatch(deleteCompanyEmployeeFailure(error.message));
        }
    };
};
