import { number } from "yup";
import { CompanyUserApi } from "../../../api/companyUser.service";
import { AddCompanyUser } from '../types';

// Action types
export const ADD_COMPANY_USER_REQUEST = 'ADD_COMPANY_USER_REQUEST';
export const ADD_COMPANY_USER_SUCCESS = 'ADD_COMPANY_USER_SUCCESS';
export const ADD_COMPANY_USER_FAILURE = 'ADD_COMPANY_USER_FAILURE';

export const DELETE_COMPANY_USER_REQUEST = 'DELETE_COMPANY_USER_REQUEST';
export const DELETE_COMPANY_USER_SUCCESS = 'DELETE_COMPANY_USER_SUCCESS';
export const DELETE_COMPANY_USER_FAILURE = 'DELETE_COMPANY_USER_FAILURE';

// Action creators
const addCompanyUserRequest = () => ({
    type: ADD_COMPANY_USER_REQUEST
});

const addCompanyUserSuccess = (companyUser: AddCompanyUser) => ({
    type: ADD_COMPANY_USER_SUCCESS,
    payload: companyUser
});

const addCompanyUserFailure = (error: string) => ({
    type: ADD_COMPANY_USER_FAILURE,
    payload: error
});

const deleteCompanyUserRequest = () => ({
    type: DELETE_COMPANY_USER_REQUEST
});

const deleteCompanyUserSuccess = (userId: string) => ({
    type: DELETE_COMPANY_USER_SUCCESS,
    payload: userId
});

const deleteCompanyUserFailure = (error: string) => ({
    type: DELETE_COMPANY_USER_FAILURE,
    payload: error
});

export const addCompanyUser = (companyId: number, companyUser: AddCompanyUser) => {
    return async (dispatch: any, getState: any) => {
        companyUser.companyId = companyId;
        dispatch(addCompanyUserRequest());
        try {
            const result = await CompanyUserApi.addCompanyUser(companyUser);

            if (!result) {
                dispatch(addCompanyUserFailure('Failed to add company user'));
                return;
            }

            const addedCompanyUser = result.data;
            dispatch(addCompanyUserSuccess(addedCompanyUser));
        } catch (error: any) {
            dispatch(addCompanyUserFailure(error.message));
        }
    };
};

export const deleteCompanyUser = (userId: string) => {
    return async (dispatch: any, getState: any) => {
        dispatch(deleteCompanyUserRequest());
        try {
            const result = await CompanyUserApi.removeCompanyUser(userId);

            if (!result) {
                dispatch(deleteCompanyUserFailure('Failed to delete company user'));
                return;
            }

            dispatch(deleteCompanyUserSuccess(userId));
        } catch (error: any) {
            dispatch(deleteCompanyUserFailure(error.message));
        }
    };
};
