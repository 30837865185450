import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../config/reducerConfig/rootReducer';
import { getCompanyEmployees } from '../actions/companyActions';
import { deleteCompanyEmployee } from '../actions/companyEmployeeActions';
import { AppButton,  AreYouSureAppButton } from '../../../components';
import {  DeleteIcon,  PlusIcon } from '../../../components/Icon';

import { CompanyEmployee, Company } from '../types';
import { useDispatch } from 'react-redux';
import { AppBreadcrumbs, Spinner } from '../../../components';
import { useHistory } from 'react-router-dom';


interface CompanyEmployeesProps {
    companyId: number;
    company: Company

    employees?: CompanyEmployee[];
    employeesLoading?: boolean;
    employeeError?: string | null;
}

export const CompanyEmployeesView: React.FC<CompanyEmployeesProps> = ({ companyId, employees = [], employeesLoading, employeeError, company }) => {
    const dispatch = useDispatch();
    const [searchQuery, setSearchQuery] = useState('');
    const history = useHistory();


    useEffect(() => {
        dispatch(getCompanyEmployees(companyId));
    }, [companyId, dispatch]);

    const filteredEmployees = employees.filter(employee =>
        employee.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        employee.phoneNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
        employee.email.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleAddEmployeeClick = () => {
        history.push(`/company/addemployee/${companyId}`);
    };


    const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const handleDeleteEmployee = (employeeId: any) => {
            dispatch(deleteCompanyEmployee(employeeId.employeeId));
        
    };


    return (
        <div className="main-content-item company-page">
            <AppBreadcrumbs pages={[{ name: 'Employees /' }]} />

            <div className="companies-wrapper">


                <div className="app-booking-table-title">
                    <div style={{ fontWeight: 'bold', fontSize: 'larger' }}>Employees of: {company.companyName}</div>
                </div>
                <div style={{ marginBottom: '40px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <input
                        type="text"
                        placeholder="Search employees..."
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                    />
                    <AppButton
                        size='medium'
                        designType="teal"
                        isIcon={true}
                        dataTestId="test-add-employee"
                        onClick={handleAddEmployeeClick}
                    >
                        <PlusIcon />
                    </AppButton>
                </div>

                <div className="company-list-wrapper">
                    {employeesLoading ? <Spinner /> : (
                        <table className="company-list ui very basic table fixed">
                            <thead>
                                <tr className="company-table-header sticky" style={{ zIndex: 100, backgroundColor: '#ffffffeb' }}>
                                    <th>Name</th>
                                    <th>Phone Number</th>
                                    <th>Email</th>
                                    <th></th>

                                </tr>
                            </thead>
                            <tbody>
                                {filteredEmployees.length === 0 ? (
                                    <tr>
                                        <td colSpan={4}>No employees</td>
                                    </tr>
                                ) : (
                                    filteredEmployees.map((employee, index) => (
                                        <tr key={index} className="app-companies-company">
                                            <td>{employee.name}</td>
                                            <td>{employee.phoneNumber}</td>
                                            <td>{employee.email}</td>
                                            <td>
                                                <div className="ui icon buttons">

                                                <AreYouSureAppButton
                                                    dataTestIdOk={"test-employee-do-delete-" + employee.companyEmployeeId}
                                                    dataTestIdConfirm={'test-employee-delete-confirm' + employee.companyEmployeeId}
                                                    mainBtndesignType="negative"
                                                    size='mini'
                                                    text={<><DeleteIcon /></>}
                                                    onClick={() => { handleDeleteEmployee({ employeeId: employee.companyEmployeeId as number }) }}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    employees: state.companyEmployees.employees,
    employeesLoading: state.companyEmployees.loading,
    error: state.companyEmployees.error
});

export default connect(mapStateToProps)(CompanyEmployeesView);

