import { ApiResponse } from '../commonTypes';
import { AddCompanyUser } from '../features/Company/types';
import { BaseService } from './base.service';

class CompanyUserService extends BaseService {
    private static _authService: CompanyUserService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): CompanyUserService {
        return this._authService || (this._authService = new this('companyUser'));
    }

    public async addCompanyUser(dataToServer: AddCompanyUser): Promise<ApiResponse> {
        try {
            const axiosResponse = await this.$http.post('', dataToServer);
            return { response: axiosResponse, data: axiosResponse.data };
        } catch (error: any) {
            this.handleAllErrors(error);
            throw error;
        }
    }

    public async removeCompanyUser(userId: string): Promise<ApiResponse> {
        try {
            const axiosResponse = await this.$http.delete(`/${userId}`);
            return { response: axiosResponse, data: axiosResponse.data };
        } catch (error: any) {
            this.handleAllErrors(error);
            throw error;
        }
    }
}

export const CompanyUserApi = CompanyUserService.Instance;
