import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Company } from '../types';
import { useDispatch } from 'react-redux';
import { AppButton, AppTitleMobileOnly } from '../../../components';
import { EditIcon, PlusIcon, UsersIcon, PeopleIcon, OrdersIcon } from '../../../components/Icon';

interface Props {
    companies: Array<Company>;
}

const TABLE_TEXTS = {
    companyName: 'Firmanavn',
    contactEmail: 'Email',
    contactPhone: 'Telefon',
    contactPerson: 'Kontakt person',
};

export const CompanyList: React.FC<Props> = ({ companies }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const history = useHistory();

    const filteredCompanies = companies.filter(company =>
        company.companyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        company.companyEmail.toLowerCase().includes(searchQuery.toLowerCase()) ||
        company.representativeName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const handleAddCompanyClick = () => {
        // Navigate to the page for adding a new company
        history.push('/company/add');
    };


    return (
        <div>
            <div style={{ marginBottom: '40px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <input
                    type="text"
                    placeholder="Search companies..."
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                    style={{ marginRight: '10px' }}

                />
                <AppButton
                    size='medium'
                    designType="teal"
                    isIcon={true}
                    dataTestId="test-add-company"
                    onClick={handleAddCompanyClick}
                >
                    <PlusIcon />
                </AppButton>
            </div>
            <table className="company-list ui very basic table fixed">
                <thead>
                    <tr className="company-table-header sticky" style={{ zIndex: 100, backgroundColor: '#ffffffeb' }}>
                        <th>{TABLE_TEXTS.companyName}</th>
                        <th>{TABLE_TEXTS.contactEmail}</th>
                        <th>{TABLE_TEXTS.contactPhone}</th>
                        <th>{TABLE_TEXTS.contactPerson}</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredCompanies.map(company => (
                        <CompanyListElement key={company.companyId} company={company} />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

interface CompanyListElementProps {
    company: Company;
}

const CompanyListElement: React.FC<CompanyListElementProps> = ({ company }) => {
    const history = useHistory();


    const handleDetailsClick = () => {
        history.push(`/company/details/${company.companyId}`);
    };

    const handleTripsClick = () => {
        history.push(`/company/trips/${company.companyId}`);
    };

    const handleEmployeesClick = () => {
        history.push(`/company/employees/${company.companyId}`);
    };
    const handleUsersClick = () => {
        history.push(`/company/users/${company.companyId}`);
    };

    return (
        <tr data-testid={`test-company-${company.companyName}`} className="app-companies-company">
            <td>
                <AppTitleMobileOnly text={TABLE_TEXTS.companyName} />
                {company.companyName}
            </td>
            <td>
                <AppTitleMobileOnly text={TABLE_TEXTS.contactEmail} />
                {company.companyEmail}
            </td>
            <td>
                <AppTitleMobileOnly text={TABLE_TEXTS.contactPhone} />
                {company.companyPhoneNumber}
            </td>
            <td>
                <AppTitleMobileOnly text={TABLE_TEXTS.contactPerson} />
                {company.representativeName}
            </td>
            <td>

                <div className="ui icon buttons">

                <AppButton dataTestId={"test-company-element-editor-"}
                    size='mini' onClick={() => handleDetailsClick()}><>
                        <EditIcon /></>
                </AppButton>
                    <div style={{ marginRight: '2px' }} />

                <AppButton dataTestId={"test-company-element-editor-"}
                    size='mini' onClick={() => handleEmployeesClick()}><>
                        <PeopleIcon /></>
                </AppButton>
                    <div style={{ marginRight: '2px' }} />

                <AppButton dataTestId={"test-company-element-editor-"}
                    size='mini' onClick={() => handleUsersClick()}><>
                        <UsersIcon /></>
                </AppButton>
                    <div style={{ marginRight: '2px' }} />

                <AppButton dataTestId={"test-company-element-editor-"}
                    size='mini' onClick={() => handleTripsClick()}><>
                        <OrdersIcon /></>
                </AppButton>
                </div>

            </td>
        </tr>
    );
};

