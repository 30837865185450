import { CountryPriceConfigCarType, CountryPriceConfig, CountryPriceConfigDayTimeZone, CountryPriceConfigDayTypeFactor, PriceConfigCommonData } from "../types";
import * as yup from 'yup';


/** container actions */
export interface CountryPriceConfActions {
    changeCountry:  (countryId: number) => void 
    publishConfig: (isSaving:boolean) => void,
    edit: (index: number | null, modalType: ModalType) => void,
    closeEditor: () => void,
    resetDraft: () => void,
    updateComment: (comment:string) => any,
    goToRegionPriceConfigEditor:(regionId:number) =>void,
    goToPublishedVersion: () => string
}

/** other types, enums, constants */
export enum ModalType {
    countryPriceFactors = 1,
    dateTypeFactors = 2,
    dayTimeZoneFactors = 3,
    regionFactors = 4,
    carTypeFactors = 5,
    versionInfo = 6
}

/** component props  */
export interface CountryPriceConfActionsProps {
    actions: CountryPriceConfActions
}

/** state */

export interface CountryPriceConfigState {
    priceConfig: CountryPriceConfig | null,
    dayTimeZones: Array<CountryPriceConfigDayTimeZone>,
    dayTypes: Array<CountryPriceConfigDayTypeFactor>,
    carTypes: Array<CountryPriceConfigCarType>
    loading: boolean,
    publishing: boolean,
    saving: boolean,
    isEdited:boolean,
    comment: string | null,
    resettingDraft: boolean
}

/* REQUESTS AND RESPONSES FROM/TO SERVER */

// uses for both get/post: GetCountryPriceConfig and publish CountryPriceConfig
export interface CountryPriceConfigServerData {
    countryPriceConfig: CountryPriceConfig,
    dayTimeZones: Array<CountryPriceConfigDayTimeZone> | null,
    dayTypes: Array<CountryPriceConfigDayTypeFactor>
    carTypes: Array<CountryPriceConfigCarType>
}

export interface CountryPriceConfigSaveOrPubPostData extends CountryPriceConfigServerData {
    extraSavingInfo: PriceConfigCommonData
}

export interface ResetDraftPostData {
    countryPriceConfigId: number,
    userId:number,
    countryId:number
}

/* Validation */

export const countryPriceConfigValidation = yup.object().shape({
    startPrice: yup.number().min(50, "Startpris må være mellom 50 og 130.").max(130, "Startpris må være mellom 50 og 130.").required('Startpris er påkrevd'),
    pricePerKm: yup.number().min(12.5, "Kilometerpris må være mellom 12.5 og 25.").max(25, "Kilometerpris må være mellom 12.5 og 25.").required('Kilometerpris er påkrevd'),
    minPrice: yup.number().min(10, "Minstepris må være mellom 10 og 300.").max(300, "Minstepris må være mellom 10 og 300.").required('Minstepris er påkrevd'),
    waypointAddition: yup.number().min(0, "Waypoint må være mellom 0 og 50.").max(50, "Waypoint må være mellom 0 og 50.").required('Waypoint tillegg er påkrevd'),
    preOrderAddition: yup.number().min(0, "Forhåndsbestilling må være mellom 0 og 100.").max(100, "Forhåndsbestilling må være mellom 0 og 100.").required('Forhåndsbestilling er påkrevd'),
    vatRate: yup.number().min(0, "MVA-sats må være mellom 0 og 0.25.").max(0.25,"MVA-sats må være mellom 0 og 0.25.").required('MVA-sats er påkrevd'),
    startHolidayExtraHours: yup.number().min(0, "Start helligdagperiode må være mellom 0 og 12.").max(12,"Start helligdagperiode må være mellom 0 og 12.").required('Start helligdagperiode er påkrevd').nullable(true),
    endHolidayExtraHours: yup.number().min(0, "Slutt helligdagperiode må være mellom 0 og 12.").max(12,"Slutt helligdagperiode må være mellom 0 og 12.").required('Slutt helligdagperiode er påkrevd').nullable(true),
    lateCancelMaxPrice: yup.number().min(0.00001, "LateCancel maks gebyr må være større enn 0.").required('LateCancel maks gebyr er påkrevd').nullable(true),
    noShowMaxPrice: yup.number().min(0.00001, "NoShow maks gebyr må være større enn 0.").required('NoShow maks gebyr er påkrevd').nullable(true),
    cancellationGraceTime: yup.number().min(1, "Cancel grace time må være større enn 1.").nullable(true),
    maximumNumberOfActiveBookingsPerCustomer: yup.number().typeError('Påkrevd').min(1, "Maks. antall aktive bestillinger må være større enn 0 .").required('Påkrevd'),
    maximumNumberOfPreOrderBookingsPerCustomer: yup.number().typeError('Påkrevd').min(1, "Maks. antall forhåndsbestillinger må være større enn 0.").required('Påkrevd'),
})

export const dayTimeZoneFactorValidation = yup.object().shape({
    priceFactor: yup.number().typeError("Påkrevd").min(0.5, "Prisfaktor må være mellom 0.5 og 2.0.").max(2, "Prisfaktor må være mellom 0.5 og 2.0.").required('Påkrevd')
})


export const dateTypeFactorValidation = yup.object().shape({
    dayTypeId:  yup.number().nullable(true),
    priceFactor: yup.number()
    .min(0.5, "Prisfaktor må være mellom 0.5 og 1.5.").max(1.5, "Prisfaktor må være mellom 0.5 og 1.5.")
    .when('dayTypeId', {
        is: (dayTypeId:number) => dayTypeId === 8,
        then: yup.number().min(1, "Prisfaktor må være mellom 1 og 2").max(2, "Prisfaktor må være mellom 1 og 2")
      })
    .required('Påkrevd')
})


export const carTypePriceConfigValidation = yup.object().shape({
    priceFactor: yup.number().min(1, "Prisfaktor må være mellom 1 og 3.").max(3, "Prisfaktor må være mellom 1 og 3.").required('Påkrevd')
})