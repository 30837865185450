import * as yup from 'yup';
import { PriceConfigCommonData, RegionPriceConfig } from "../types";

/** state */
export interface RegionPriceConfigsState {
    list: Array<RegionPriceConfig>,
    loading: boolean,
    publishingIndex: number | null
}

export interface RegionPriceConfigState {
    priceConfig: RegionPriceConfig | null,
    loading: boolean,
    publishing: boolean,
    saving: boolean,
    isEdited:boolean,
    comment: string | null,
    resettingDraft: boolean
}

/* REQUESTS AND RESPONSES FROM/TO SERVER */

export interface RegionPriceConfigServerData {
    regionPriceConfig: RegionPriceConfig,
}

export interface RegionPriceConfigSaveOrPubPostData extends RegionPriceConfigServerData {
    extraSavingInfo: PriceConfigCommonData
}

export interface ResetRegionPriceConfigDraftPostData {
    regionPriceConfigId: number,
    userId:number,
    regionId:number
}

/** other types, enums, constants */
export enum RegionPriceConfigModalType {
    regionPriceFactors = 1,
    versionInfo = 2
}

/** container actions */
export interface RegionPriceConfActions {
    changeRegion:  (regionId: number) => void,
    changeCountry: (countryId: number)=> void,
    publishOrSaveConfig: (isSaving:boolean) => void,
    edit: (index: number | null, modalType: RegionPriceConfigModalType) => void,
    closeEditor: () => void,
    resetDraft: () => void,
    updateComment: (comment:string) => void,
    goToDrivingAreaPriceConfigEditor: (drivingAreaId: number) => void,
    goToPublishedVersion: () => string
}

export interface RegionPriceConfActionsProps {
    actions: RegionPriceConfActions
}

/* Validation */

export const regionPriceConfigValidation = yup.object().shape({
    priceFactor: yup.number().typeError("Påkrevd").min(0.5, "Prisfaktor må være mellom 0.5 og 2.").max(2, "Prisfaktor må være mellom 0.5 og 2.").required('Påkrevd'),
    minPrice: yup.number().typeError("Påkrevd").min(10, "Minstepris må være mellom 10 og 300.")
    .max(300, "Minstepris må være mellom 10 og 300.").required('Minstepris er påkrevd')
})


