import { ApiResponse } from '../commonTypes';
import { Company } from '../features/Company/types';
import { BaseService } from './base.service';

class CompanyService extends BaseService {
    private static _authService: CompanyService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): CompanyService {
        return this._authService || (this._authService = new this('company'));
    }

    public async getCompanies(): Promise<ApiResponse> {

        let axiosResponse = null;
        let url = ``
        try {

            axiosResponse = await this.$http.get(url);
            return { response: axiosResponse, data: axiosResponse.data };

        }
        catch (error: any) {
            this.handleAllErrors(error);
            throw error;
        }
    }

    public async getCompany(id: number): Promise<ApiResponse> {

        let axiosResponse = null;
        let url = `/${id}`


        try {

            axiosResponse = await this.$http.get(url);
            return { response: axiosResponse, data: axiosResponse.data };

        }
        catch (error: any) {
            this.handleAllErrors(error);
            throw error;
        }
    }

    public async getCompanyFinancialReport(fromDate: string, toDate: string, companyId: number): Promise<ApiResponse> {
        let axiosResponse = null;
        let url = `/getCompanyFinancialReport/${companyId}`;
        const requestData = {
            from: fromDate,
            to: toDate
        };
        try {
            axiosResponse = await this.$http.post(url,requestData);
            return { response: axiosResponse, data: axiosResponse.data };
        } catch (error: any) {
            this.handleAllErrors(error);
            throw error;
        }
    }


    public async addCompany(dataToServer: Company): Promise<ApiResponse> {
        let axiosResponse = null;

        try {
            axiosResponse = await this.$http.post('', dataToServer);
            return { response: axiosResponse, data: axiosResponse.data };
        }
        catch (error: any) {
            this.handleAllErrors(error);
            throw error;
        }
    }

    public async updateCompany(dataToServer: Company): Promise<ApiResponse> {
        let axiosResponse = null;
        let url = `/${dataToServer.companyId}`


        try {
            axiosResponse = await this.$http.put(url, dataToServer);
            return { response: axiosResponse, data: axiosResponse.data };
        }
        catch (error: any) {
            this.handleAllErrors(error);
            throw error;
        }
    }

    public async getCompanyEmployees(id: number): Promise<ApiResponse> {

        let axiosResponse = null;
        let url = `/${id}/employees`;


        try {

            axiosResponse = await this.$http.get(url);
            return { response: axiosResponse, data: axiosResponse.data };

        }
        catch (error: any) {
            this.handleAllErrors(error);
            throw error;
        }
    }

    public async getCompanyUsers(id: number): Promise<ApiResponse> {

        let axiosResponse = null;
        let url = `/${id}/users`;


        try {

            axiosResponse = await this.$http.get(url);
            return { response: axiosResponse, data: axiosResponse.data };

        }
        catch (error: any) {
            this.handleAllErrors(error);
            throw error;
        }
    }


}

export const CompanyApi = CompanyService.Instance;