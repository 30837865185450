import React from 'react';
import { Company } from '../types';
import { AppBreadcrumbs, AppButton, Spinner } from '../../../components';
import { CompanyList } from './CompanyList';
import '../../../styles/company.css'





interface CompanyViewProps {
    companies: Array<Company>
    companiesLoading: boolean,

}

export const CompanyView: React.FC<CompanyViewProps> = (props) => {
    const { companies, companiesLoading }= props;
    return (
        <div className="main-content-item company-page">
            <AppBreadcrumbs pages={[{ name: 'Firmaer /' }]} />

            <div className="companies-wrapper">


                <div style={{ marginBottom: '40px' }}></div>

                <div className="app-booking-table-title">
                    <div style={{ fontWeight: 'bold', fontSize: 'larger' }}>Firmaer</div>
                </div>

                <div data-testid="companylistwrapper" className="company-list-wrapper">
                    <CompanyList companies={companies}/>
                    {
                        companiesLoading ? <Spinner /> : ''
                    }
                </div>

            </div>        </div>
    );
};

export default CompanyView;
