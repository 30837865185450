import React, { useState } from 'react';
import { AppBreadcrumbs } from '../../../components';
import { CompanyEmployee, Company } from '../types';
import { addCompanyEmployee } from '../actions/companyEmployeeActions';
import { useDispatch } from 'react-redux';
import '../../../styles/company.css'
import { AppButton } from '../../../components';
import { SaveIcon } from '../../../components/Icon';

interface CompanyAddEmployeeProps {
    companyId: number;
    company: Company

}

export const CompanyEmployeeAddView: React.FC<CompanyAddEmployeeProps> = ({ companyId, company }) => {
    const [formData, setFormData] = useState<CompanyEmployee>({
        name: '',
        phoneNumber: '',
        email: ''
    });
    const dispatch = useDispatch();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const isConfirmed = window.confirm("Are you sure you want to submit?");
        if (isConfirmed) {
            dispatch(addCompanyEmployee(companyId, formData));
        }
    };

    return (
        <div className="main-content-item company-page">
            <AppBreadcrumbs pages={[{ name: 'Add employee for' }]} />

            <div className="companies-wrapper">
                <div style={{ marginBottom: '40px' }}></div>

                <div className="app-company-table-title">
                    <div style={{ fontWeight: 'bold', fontSize: 'larger' }}>Add employee to: {company.companyName}</div>
                </div>

                <div className="company-list-wrapper">
                    <form onSubmit={handleSubmit}>

                        <div className="company-form-group">
                            <label className="company-form-label">Name:</label>
                            <input type="text" name="name" value={formData.name} onChange={handleChange} className="company-form-input" required />
                        </div>
                        <div className="company-form-group">
                            <label className="company-form-label">Phone Number:</label>
                            <input type="text" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} className="company-form-input" required />
                        </div>
                        <div className="company-form-group">
                            <label className="company-form-label">Email:</label>
                            <input type="text" name="email" value={formData.email} onChange={handleChange} className="company-form-input" required />
                        </div>

                        <AppButton
                            size='medium'
                            designType="teal"
                            isIcon={true}
                            loading={false}
                            disabled={false}
                            dataTestId="test-add-company"
                            type='submit'
                        >
                            <> {'Lagre '}<SaveIcon /></>
                        </AppButton>                    </form>
                </div>
            </div>
        </div>
    );
};

export default CompanyEmployeeAddView;

