import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { RootState } from '../../../config/reducerConfig/rootReducer';
import { getCompanyTrips } from '../actions/companyTripsActions';
import { CompanyTrip, Company } from '../types';
import { AppBreadcrumbs, Spinner } from '../../../components';
import DayPicker from 'react-day-picker/DayPicker';
import '../../../styles/company.css'
import { getCompanyFinancialReport } from '../actions/companyActions';
import ReportExcel from '../../Reports/child-components/ReportExcel';

interface CompanyTripsProps {
    companyId: number;
    company: Company

    trips?: CompanyTrip[];
    tripsLoading?: boolean;
    tripsError?: string | null;
}

export const CompanyTripsView: React.FC<CompanyTripsProps> = ({ companyId, trips = [], tripsLoading, tripsError, company }) => {
    const dispatch = useDispatch();

    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const [fromDate, setFromDate] = useState<Date>(firstDayOfMonth);
    const [toDate, setToDate] = useState<Date>(lastDayOfMonth);

    useEffect(() => {
        dispatch(getCompanyTrips(companyId, fromDate, toDate));
    }, [companyId, dispatch, fromDate,toDate]);

    const handleFromDayClick = (day: Date) => {
        setFromDate(day);
    };

    const handleToDayClick = (day: Date) => {
        setToDate(day);
    };
    const formatDate = (date: Date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleExportReportClick = async (companyId: number) => {
        try {
            const fromDateString = formatDate(fromDate);
            const toDateString = formatDate(toDate);
            dispatch(getCompanyFinancialReport(fromDateString, toDateString, companyId));
        } catch (error) {
            console.error('Error exporting report:', error);
        }
    };
    return (
        <div className="main-content-item company-page">
            <AppBreadcrumbs pages={[{ name: 'Trips /' }]} />

            <div className="companies-wrapper">
                <div style={{ marginBottom: '40px' }}></div>

                <div className="app-booking-table-title">
                    <div style={{ fontWeight: 'bold', fontSize: 'larger' }}>Trips for: {company.companyName}</div>
                </div>
                <div className="date-picker-container">
                    <div>
                        <div>From:</div>
                        <DayPicker
                            selectedDays={fromDate}
                            onDayClick={handleFromDayClick} />
                    </div>
                    <div>
                        <div>To:</div>

                        <DayPicker
                            selectedDays={toDate}
                            onDayClick={handleToDayClick} />
                    </div>
                    <ReportExcel button={<button onClick={() => handleExportReportClick(company.companyId!)}>Rapport</button>} />

                </div>

                <div className="company-list-wrapper">
                    {tripsLoading ? <Spinner /> : (
                        <table className="company-list ui very basic table fixed">
                            <thead>
                                <tr className="company-table-header sticky" style={{ zIndex: 100, backgroundColor: '#ffffffeb' }}>
                                    <th>Time</th>
                                    <th>Price</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {trips.length === 0 ? (
                                    <tr>
                                        <td colSpan={3}>No trips</td>
                                    </tr>
                                ) : (
                                    trips.map((trip, index) => (
                                        <tr key={index} className="app-companies-company">
                                            <td>{trip.captureTime}</td>
                                            <td>{trip.price}</td>
                                            <td>{trip.statusName}</td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    trips: state.companyTrips.trips,
    tripsLoading: state.companyTrips.loading,
    tripsError: state.companyTrips.error
});

export default connect(mapStateToProps)(CompanyTripsView);
