import React from 'react';
import {Icon as SemanticIcon, Popup} from 'semantic-ui-react'

interface Props {
    name:string
}

export const Icon: React.FC<Props> = ({name}) => {
    return <i className={name}></i>
};

export const UserIcon: React.FC = () => {
    return <Icon name="user cirlce icon" />
}

export const UserIcon2: React.FC = () => {
    return <Icon name=" user circle outline" />
}

export const OffIcon: React.FC = () => {
    return <Icon name="power off icon" />
}

export const GogIcon: React.FC = () => {
    return <Icon name="cogs icon" />
}

export const DashboardIcon: React.FC = () => {
    return <Icon name="icon tachometer alternate" />
}

export const PeopleIcon: React.FC = () => {
    return <Icon name="icon users" />
}

export const OrdersIcon: React.FC = () => {
    return <Icon name="icon shopping cart" />
}

export const InfoListIcon: React.FC = () => {
    return <Icon name="icon clipboard list" />
}

export const TaxiIcon: React.FC = () => {
    return <Icon name="icon taxi" />
}

export const PhoneIcon: React.FC = () => {
    return <Icon name="icon phone square" />
}

export const EmailIcon: React.FC = () => {
   return <Icon name="icon at" />
}

export const UsersIcon: React.FC = () => {
    return <Icon name="icon users" />
}

export const ArrowLeftIcon: React.FC = () => {
    return <Icon name="icon arrow left" />
}

export const CalenderIcon: React.FC = () => {
    return <Icon name="icon calendar alternate outline" />
}

export const CalenderOutlineIcon: React.FC = () => {
    return <Icon name="icon calendar outline" />
}

export const StartIcon: React.FC = () => {
    return <Icon name="icon map marker alt" />
}

export const WpIcon: React.FC = () => {
   return <Icon name="icon arrow alt right" />
}

export const FinishIcon: React.FC = () => {
   return <Icon name="icon flag checkered" />
}

export const CheckIcon: React.FC = () => {
    return <Icon name="icon check" />
 }
 

export const SortAUp: React.FC = () => {
    return <Icon name="icon street view " />
}

export const PriceIcon: React.FC = () => {
    return <Icon name="icon credit card" />
}

export const RegionIcon: React.FC = () => {
    return <Icon name="icon map outline" />
}

export const HolidaysIcon: React.FC = () => {
    return <Icon name="icon calendar plus outline" />
}

export const VideoCameraIcon: React.FC = () => {
    return <Icon name="icon video camera" />
}


export const PlusIcon: React.FC = () => {
    return <Icon name="icon plus" />
}

export const EditIcon: React.FC = () => {
    return <Icon name="icon pencil" />
}

export const DeleteIcon: React.FC = () => {
    return <Icon name="icon trash alternate outline" />
}

export const MapIcon: React.FC = () => {
    return <Icon name="icon map outline" />
}

export const SaveIcon: React.FC = () => {
    return <Icon name="icon save" />
}

export const EyeIcon: React.FC = () => {
    return <Icon name="icon eye" />
}
export const EyeIconSlash: React.FC = () => {
    return <Icon name="icon eye slash" />
}

export const UndoIcon: React.FC = () => {
    return <Icon name="icon undo" />
}

export const LinkIcon: React.FC = () => {
    return <Icon name="icon info linkify" />
}

export const DotIcon: React.FC = () => {
    return <Icon name="icon dot circle" />
}

export const MapPinIcon: React.FC = () => {
    return <Icon name="icon map pin" />
}

export const GlobIcon: React.FC = () => {
    return <Icon name="icon globe" />
}

export const MapAlternateIcon: React.FC = () => {
    return <Icon name="icon map marker alternate" />
}

export const CalculatorIcon: React.FC = () => {
    return <Icon name="icon calculator " />
}

export const SendIcon: React.FC = () => {
    return <Icon name="icon paper plane " />
}

export const CloseIcon: React.FC = () => {
    return <Icon name="icon close " />
}

export const FileIcon: React.FC = () => {
    return <Icon name="icon file alternate" />
}

export const CopyIcon: React.FC = () => {
    return <Icon name="icon copy outline" />
}

export const XCloseIcon: React.FC = () => {
    return <Icon name="icon times circle outline" />
}


export const TableIcon: React.FC = () => {
    return <Icon name='icon th list' />
}

export const DiscountIcon: React.FC = () => {
    return <Icon name='icon tag' />
}

export const DownloadIcon: React.FC = () => {
    return <Icon name='icon download' />
}

export const FoodIcon: React.FC = () => {
    return <Icon name="user food icon" />
}

export const CompanyIcon: React.FC = () => {
    return <Icon name="icon factory" />
}

export const AttentionIcon: React.FC = () => {
    return <SemanticIcon size='small' name='exclamation triangle' />
}

export type AppIconName = 'exclamation triangle' | 'users' | 'user' | 'info' | 'comment alternate';
export type AppIconWithPopupPos = 'right center' | 'left center' | 'top center' | 'top left' | 'top right'

export const AppIconWithPopup = ({color='black', size='small', iconName='exclamation triangle', dataTestId='app-icon-popup', content='', position='top center'}:
 {size?: 'small', iconName:AppIconName, dataTestId?:string, content:string, position?: AppIconWithPopupPos, color:'orange' | 'black' | 'red' | 'grey'}) => {
   return  <Popup
      trigger={<SemanticIcon data-testid={dataTestId} color={color} size={size} name={iconName} style={{marginLeft:'3px', cursor:'pointer'}} />}
      position={position}
      on='click'
      style={{padding: '2rem', letterSpacing: '1px', lineHeight: '1.5rem', fontSize:'9px !important',  whiteSpace: 'pre-wrap'}}
      size={'small'}
    >{content}</Popup>
}



