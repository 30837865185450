import { ApiResponse } from '../commonTypes';
import { CompanyEmployee } from '../features/Company/types';
import { BaseService } from './base.service';

class CompanyEmployeeService extends BaseService {
    private static _authService: CompanyEmployeeService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): CompanyEmployeeService {
        return this._authService || (this._authService = new this('companyEmployee'));
    }

    public async addCompanyEmployee(dataToServer: CompanyEmployee): Promise<ApiResponse> {
        try {
            const axiosResponse = await this.$http.post('', dataToServer);
            return { response: axiosResponse, data: axiosResponse.data };
        } catch (error: any) {
            this.handleAllErrors(error);
            throw error;
        }
    }

    public async removeCompanyEmployee(employeeId: number): Promise<ApiResponse> {
        try {
            const axiosResponse = await this.$http.delete(`/${employeeId}`);
            return { response: axiosResponse, data: axiosResponse.data };
        } catch (error: any) {
            this.handleAllErrors(error);
            throw error;
        }
    }
}

export const CompanyEmployeeApi = CompanyEmployeeService.Instance;
