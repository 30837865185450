import { CompanyTripsApi } from "../../../api/companyTrips.service";
import { CompanyTrip } from '../types';

// Action types
export const FETCH_TRIPS_REQUEST = 'FETCH_TRIPS_REQUEST';
export const FETCH_TRIPS_SUCCESS = 'FETCH_TRIPS_SUCCESS';
export const FETCH_TRIPS_FAILURE = 'FETCH_TRIPS_FAILURE';

// Action creators
const fetchTripsRequest = () => ({
    type: FETCH_TRIPS_REQUEST
});

const fetchTripsSuccess = (trips: CompanyTrip[]) => ({
    type: FETCH_TRIPS_SUCCESS,
    payload: trips
});

const fetchTripsFailure = (error: string) => ({
    type: FETCH_TRIPS_FAILURE,
    payload: error
});

export const getCompanyTrips = (id: number, from: Date, to: Date) => {
    return async (dispatch: any, getState: any) => {
        dispatch(fetchTripsRequest());
        try {
            const result = await CompanyTripsApi.getCompanyTrips(id,from,to);

            if (result === undefined) {
                throw new Error('Failed to fetch company trips');
            }
            const trips = result.data;
            dispatch(fetchTripsSuccess(trips));
        } catch (error: any) {
            console.error('Error fetching company trips:', error);

            dispatch(fetchTripsFailure(error.message));
        }
    };
};

