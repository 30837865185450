import { DrivingAreaPriceConfig, DrivingAreaPriceConfigDayTimeZone, DrivingAreaSpecialLocation, PriceConfigCommonData } from "../types";
import * as yup from 'yup';


/** state */
export interface DrivingAreaPriceConfigsState {
    list: Array<DrivingAreaPriceConfig>,
    loading: boolean
}

export interface PreOrderConfig {
    preOrderWeeklyTimes: Array<DrivingAreaPreOrderWeeklyTimesAllowed>,
    preOrderDateTimes: Array<DrivingAreaPreOrderDateTimesAllowed>,
    preOrderDateAllowed:Array<string>,
    preOrderDateTimesBeforeNow:  Array<DrivingAreaPreOrderDateTimesAllowed>,
   
    minimumTimeUntilPickupInMinutes: number | null,
    maximumTimeUntilPickupInMinutes: number | null
}


export interface DrivingAreaPriceConfigState {
    priceConfig: DrivingAreaPriceConfig | null,
    dayTimeZones: Array<DrivingAreaPriceConfigDayTimeZone>,
    specialLocations: Array<DrivingAreaSpecialLocation>,
    preOrderConfig: PreOrderConfig,
    loading: boolean,
    publishing: boolean,
    saving: boolean,
    isEdited:boolean,
    comment: string | null,
    resettingDraft: boolean
}

export interface DrivingAreaPreOrderWeeklyTimesAllowed {
    drivingAreaPreOrderWeeklyTimesAllowedId: number,
    divingAreaPriceConfigId:number,
    startTime: string | null,
    endTime: string | null,
    dayTypeId: number,
    maxNumberOfPreOrdersPerAllCustomers: number | null
}

export interface DrivingAreaPreOrderDateAllowed {
    date: string | null,
}

export interface DrivingAreaPreOrderDateTimesAllowed {
    drivingAreaPreOrderDateTimesAllowedId?: number,
    divingAreaPriceConfigId:number,
    startTime: string | null,
    endTime: string | null,
    date: string | null,
    maxNumberOfPreOrdersPerAllCustomers: number | null
}


/* REQUESTS AND RESPONSES FROM/TO SERVER */


export interface DrivingAreaPriceConfigServerData{
    drivingAreaPriceConfig: DrivingAreaPriceConfig,
    dayTimeZones: Array<DrivingAreaPriceConfigDayTimeZone> | null,
    specialLocations: Array<DrivingAreaSpecialLocation>,
    preOrderConfig: PreOrderConfig

}

export interface DrivingAreaSaveOrPublishPriceConfigData extends DrivingAreaPriceConfigServerData {
    extraSavingInfo: PriceConfigCommonData
}

export interface ResetDrivingAreaPriceConfigDraftPostData {
    drivingAreaPriceConfigId: number,
    userId:number,
    drivingAreaId:number
}

/** other types, enums, constants */
export enum DrivingAreaPriceConfigModalType {
    drivingAreaPriceFactors = 1,
    dayTimeZones = 2,
    specialLocations = 3,
    versionInfo = 4,
    preOrderInfo = 5
}

/** container actions */
export interface DrivingAreaPriceConfActions {
    changeRegion:  (regionId: number) => void,
    changeCountry: (countryId: number)=> void,
    changeDrivingArea: (drivingAreaId: number) => void,
    edit: (index: number | null, modalType: DrivingAreaPriceConfigModalType) => void,
    addOrUpdateSpecialLocation: (specialLocation: DrivingAreaSpecialLocation, index: number | null) => void,
    deleteSpecialLocation: (index: number) => void,
    publishOrSaveConfig: (isSaving:boolean) => void,
    closeEditor: () => void,
    updateComment: (comment:string) => void,
    goToPublishedVersion: () => string,
    resetDraft: () => void
    
}

export interface DrivingAreaPriceConfActionsProps {
    actions: DrivingAreaPriceConfActions
}

/* Validation */

export const drivingAreaPriceConfigValidation = yup.object().shape({
    priceFactor: yup.number().typeError("Påkrevd").min(0.5, "Prisfaktor må være mellom 0.5 og 2.").max(2, "Prisfaktor må være mellom 0.5 og 2.").required('Påkrevd'),
    minPrice: yup.number().typeError("Påkrevd").min(10, "Minstepris må være mellom 10 og 300.").max(300, "Minstepris må være mellom 10 og 300.").required('Minstepris er påkrevd'),
    serviceCharge: yup.number().nullable().min(0, "Servicetillegg må være større enn 0."),
    serviceChargeVatRate: yup.number().nullable().min(0, "MVA til Servicetillegg må være mellom 0 og 1.").max(1, "MVA til servicetillegg må være mellom 0 og 1."),
})

export const specialLocationPriceConfigValidation = yup.object().shape({
    name: yup.string().typeError('Påkrevd.').required('Navn er påkrevd'),
    latitude: yup.number().typeError('Påkrevd').min(-90, "Ugyldig Latitude").max(90, "Ugyldig Latitude").required('Latitude er påkrevd'),
    longitude: yup.number().typeError('Påkrevd').min(-180, "Ugyldig Longitude").max(180, "Ugyldig Longitude").required('Longitude er påkrevd'),
    radiusMeters: yup.number().typeError("Påkrevd").min(0.001, "Radius må være større enn 0.").required('Radius er påkrevd'),
    toPriceFactor: yup.number().typeError('Legg til Fra og/eller Til rabatt').min(0, "Til rabatt må være større eller lik 0.").nullable(true),
    fromPriceFactor: yup.number().min(0, "Fra rabatt må være større eller lik 0.").nullable(true)
})


export const preOrderMainInfoValidation = yup.object().shape({
    minimumTimeUntilPickupInMinutes: yup.number().typeError('Påkrevd').min(1, "Min. tid før forhåndsbestilt tur må være større enn 0.").required('Påkrevd'),
    maximumTimeUntilPickupInMinutes : yup.number().typeError('Påkrevd').min(1, "Maks tid for forhåndsbestilt tur må være større enn 0.").required('Påkrevd')
})



