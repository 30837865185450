import { ApiResponse } from '../commonTypes';
import { BaseService } from './base.service';
import { FinancialReportRequest } from '../features/Reports/types';
import { dateTimeHelper } from '../utils/dateTimeHelper';


class ReportsService extends BaseService {
  private static _authService: ReportsService;

  private constructor(controllerName: string) {
    super(controllerName);
  }

  public static get Instance(): ReportsService {
    return this._authService || (this._authService = new this('report'));
  }

  public async getFinancialReport(req: FinancialReportRequest): Promise<ApiResponse> {

    let axiosResponse = null;
    const {from, to} = req;

    if (!from || !to){
      throw new Error("From or to args. is missing.");
    }
    try {

      axiosResponse = await this.$http.get(`getFinancialReport?from=${from}&to=${to}`);
      return { response: axiosResponse, data: axiosResponse.data };
    }
    catch (error:any) {
      this.handleAllErrors(error);
      throw error;
    }
  }

  public async getFinancialReportForCompany(companyId:Number ,req: FinancialReportRequest): Promise<ApiResponse> {

    let axiosResponse = null;
    const {from, to} = req;

    if (!from || !to){
      throw new Error("From or to args. is missing.");
    }
    try {

      axiosResponse = await this.$http.get(`getFinancialReportForCompany/${companyId}?from=${from}&to=${to}`);
      return { response: axiosResponse, data: axiosResponse.data };
    }
    catch (error:any) {
      this.handleAllErrors(error);
      throw error;
    }
  }
}



export const ReportsApi = ReportsService.Instance;
