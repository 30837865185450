import { CompanyTrip } from '../types';
import { FETCH_TRIPS_REQUEST, FETCH_TRIPS_SUCCESS, FETCH_TRIPS_FAILURE } from '../actions/companyTripsActions';

interface CompanyTripsState {
    trips: CompanyTrip[];
    loading: boolean;
    error: string | null;
}

const initialState: CompanyTripsState = {
    trips: [],
    loading: false,
    error: null
};

const companyTripsReducer = (state = initialState, action: any): CompanyTripsState => {
    switch (action.type) {
        case FETCH_TRIPS_REQUEST:

            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_TRIPS_SUCCESS:
            return {
                ...state,
                trips: action.payload,
                loading: false,
                error: null
            };
        case FETCH_TRIPS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

export default companyTripsReducer;


