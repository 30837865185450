import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../config/reducerConfig/rootReducer';
import { Company } from './types';
import { CompanyView } from './child-components/CompanyView';
import { useHistory, Route, Switch } from 'react-router-dom';
import { getCompanies } from './actions/companyActions';
import { CompanyDetailsView } from './child-components/CompanyDetailsView';

import { CompanyAddView } from './child-components/CompanyAddView';
import { CompanyTripsView } from './child-components/CompanyTripsView';
import { CompanyEmployeesView } from './child-components/CompanyEmployeesView';
import { CompanyEmployeeAddView } from './child-components/CompanyEmployeeAddView';
import { CompanyUserAddView } from './child-components/CompanyUserAddView';

import { CompanyUsersView } from './child-components/CompanyUsersView';

import { useDispatch } from 'react-redux';





interface CompanyContainerProps {
    companies: Company[];
    loading: boolean;
    error: string | null;
}

const CompanyContainer: React.FC<CompanyContainerProps> = (props) => {
    const { companies, loading, error } = props;
    const dispatch = useDispatch();



    useEffect(() => {
        dispatch(getCompanies());
    }, [dispatch]); 




    return (
        <div>
            <Switch>
                <Route exact path="/company" render={() => <CompanyView companies={companies} companiesLoading={loading} />} />
                <Route exact path="/company/add" render={() => <CompanyAddView/>} />
                <Route
                    path="/company/details/:companyId"
                    render={(routeProps) => {
                        const companyId = parseInt(routeProps.match.params.companyId);
                        const company = companies.find((c) => c.companyId === companyId);
                        return company ? <CompanyDetailsView company={company} /> : <div>Company not found</div>;
                    }}
                />

                <Route
                    path="/company/trips/:companyId"
                    render={(routeProps) => {
                        const companyId = parseInt(routeProps.match.params.companyId);
                        const company = companies.find((c) => c.companyId === companyId);
                        return company ? <CompanyTripsView companyId={companyId} company={company}  {...props} /> : <div>Company not found</div>;
                    }}
                />
                <Route
                    path="/company/employees/:companyId"
                    render={(routeProps) => {
                        const companyId = parseInt(routeProps.match.params.companyId);
                        const company = companies.find((c) => c.companyId === companyId);
                        return company ? <CompanyEmployeesView companyId={companyId} company={company}  {...props} /> : <div>Company not found</div>;
                    }}
                />
                <Route
                    path="/company/addemployee/:companyId"
                    render={(routeProps) => {
                        const companyId = parseInt(routeProps.match.params.companyId);
                        const company = companies.find((c) => c.companyId === companyId);
                        return company ? <CompanyEmployeeAddView companyId={companyId} company={company}  {...props} /> : <div>Company not found</div>;
                    }}
                />
                <Route
                    path="/company/users/:companyId"
                    render={(routeProps) => {
                        const companyId = parseInt(routeProps.match.params.companyId);
                        const company = companies.find((c) => c.companyId === companyId);
                        return company ? <CompanyUsersView companyId={companyId} company={company} {...props} /> : <div>Company not found</div>;
                    }}
                />
                <Route
                    path="/company/adduser/:companyId"
                    render={(routeProps) => {
                        const companyId = parseInt(routeProps.match.params.companyId);
                        const company = companies.find((c) => c.companyId === companyId);
                        return company ? <CompanyUserAddView companyId={companyId} company={company}  {...props} /> : <div>Company not found</div>;
                    }}
                />



            </Switch>
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        companies: state.company.companies,
        loading: state.company.loading,
        error: state.company.error,
        employees: state.companyEmployees.employees,
        employeesLoading: state.companyEmployees.loading,
        employeeError: state.companyEmployees.error,
        trips: state.companyTrips.trips,
        tripsLoading: state.companyTrips.loading,
        tripsError: state.companyTrips.error,
        users: state.companyUsers.users,
        usersLoading: state.companyUsers.loading,
        userError: state.companyUsers.error



    };
};


export default connect(mapStateToProps)(CompanyContainer);
