import { CompanyUser} from '../types';
import { FETCH_USERS_REQUEST, FETCH_USERS_SUCCESS, FETCH_USERS_FAILURE } from '../actions/companyActions';

interface CompanyUsersState {
    users: CompanyUser[];
    loading: boolean;
    error: string | null;
}

const initialState: CompanyUsersState = {
    users: [],
    loading: false,
    error: null
};

const companyUsersReducer = (state = initialState, action: any): CompanyUsersState => {
    switch (action.type) {
        case FETCH_USERS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_USERS_SUCCESS:
            return {
                ...state,
                users: action.payload,
                loading: false,
                error: null
            };
        case FETCH_USERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

export default companyUsersReducer;
