import { AxiosResponse } from 'axios';
import { ApiResponse } from '../commonTypes';
import { ForgotPasswordRequestData, LoginRequestData } from '../features/Login/types';
import { BaseService } from './base.service';

class AuthService extends BaseService {
  private static _authService: AuthService;

  private constructor(controllerName: string) {
    super(controllerName);
  }

  public static get Instance(): AuthService {
    return this._authService || (this._authService = new this('users'));
  }

  public async login(credentials: LoginRequestData): Promise<ApiResponse> {

    let axiosResponse = null;

    try {
      axiosResponse = await this.$http.post('login', credentials);

      return { response: axiosResponse, data: axiosResponse.data };
    }
    catch (error: any) {
      this.handleAllErrors(error);
      throw error;
    }
  }

  public async forgotPassword(credentials: ForgotPasswordRequestData): Promise<ApiResponse> {

    let axiosResponse = null;

    try {
      axiosResponse = await this.$http.post('forgotpassword', credentials);
      return { response: axiosResponse, data: axiosResponse.data };
    }
    catch (error:any) {
      this.handleAllErrors(error);
      throw error;
    }
  }


  public async logout(): Promise<AxiosResponse | null> {
    let axiosResponse = null;

    try {
      axiosResponse = await this.$http.post('logout');
    }
    catch (error: any) { // Update the type of the error parameter to 'any'
      this.handleAllErrors(error);
      throw error;
    }

    return axiosResponse;
  }

  public async getCurrentUser(): Promise<AxiosResponse | null> {
    var axiosResponse = null;

    await this.$http.get('getCurrentUser')
      .then((response) => {
        axiosResponse = response;
      },
        (error) => {

          var url = `/Login`;
          window.location.href = url;
          throw error;
        });

    return axiosResponse;
  }

}

export const AuthApi = AuthService.Instance;
