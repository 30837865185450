import { CompanyApi } from "../../../api/company.service";
import { BookingInfoResponse } from "../../Bookings/types";
import { Company, CompanyEmployee } from '../types';

// Action types
export const FETCH_COMPANIES_REQUEST = 'FETCH_COMPANIES_REQUEST';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_FAILURE = 'FETCH_COMPANIES_FAILURE';

export const ADD_COMPANY_REQUEST = 'ADD_COMPANY_REQUEST';
export const ADD_COMPANY_SUCCESS = 'ADD_COMPANY_SUCCESS';
export const ADD_COMPANY_FAILURE = 'ADD_COMPANY_FAILURE';

export const UPDATE_COMPANY_REQUEST = 'UPDATE_COMPANY_REQUEST';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAILURE = 'UPDATE_COMPANY_FAILURE';

export const GET_COMPANY_FINANCIALREPORT_REQUEST = 'GET_COMPANY_FINANCIALREPORT_REQUEST';
export const GET_COMPANY_FINANCIALREPORT_SUCCESS = 'GET_COMPANY_FINANCIALREPORT_REQUEST';
export const GET_COMPANY_FINANCIALREPORT_FAILURE = 'GET_COMPANY_FINANCIALREPORT_REQUEST';

// Action creators
const fetchCompaniesRequest = () => ({
    type: FETCH_COMPANIES_REQUEST
});

const fetchCompaniesSuccess = (companies: Company[]) => ({

    type: FETCH_COMPANIES_SUCCESS,
    payload: companies
});

const fetchCompaniesFailure = (error: string) => ({
    type: FETCH_COMPANIES_FAILURE,
    payload: error
});
export const getCompanies = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(fetchCompaniesRequest());
        try {
            const result = await CompanyApi.getCompanies();
            if (!result) {
                dispatch(fetchCompaniesFailure('Failed to fetch companies')); 
                return;
            }

            const companies = result.data; 
            dispatch(fetchCompaniesSuccess(companies)); 
        } catch (error: any) {
            dispatch(fetchCompaniesFailure(error.message)); 
        }
    };
};
const getCompanyFinancialReportRequest = () => ({
    type: GET_COMPANY_FINANCIALREPORT_REQUEST
});

const getCompanyFinancialReportSuccess = (result: any) => ({

    type: getCompanyFinancialReportSuccess,
    payload: result.data
});

const getCompanyFinancialReportFailure = (error: string) => ({
    type: getCompanyFinancialReportFailure,
    payload: error
});
export const getCompanyFinancialReport = (fromDate:string,toDate:string,companyId:number) => {
    return async (dispatch: any, getState: any) => {
        dispatch(getCompanyFinancialReportRequest());
        try {
            const result = await CompanyApi.getCompanyFinancialReport(fromDate,toDate,companyId);
            if (!result) {
                dispatch(getCompanyFinancialReportFailure('Failed to fetch companyReport')); 
                return;
            }

            const data = result.data; 
            dispatch(getCompanyFinancialReportSuccess(data)); 
        } catch (error: any) {
            dispatch(getCompanyFinancialReportFailure(error.message)); 
        }
    };
};
// Action creators for adding a company
const addCompanyRequest = () => ({
    type: ADD_COMPANY_REQUEST
});

const addCompanySuccess = () => ({
    type: ADD_COMPANY_SUCCESS,
    
});

const addCompanyFailure = (error: string) => ({
    type: ADD_COMPANY_FAILURE,
    payload: error
});

export const addCompany = (companyData: Company) => {
    return async (dispatch: any, getState: any) => {
        dispatch(addCompanyRequest());
        try {
            const result = await CompanyApi.addCompany(companyData);
            if (!result) {
                dispatch(addCompanyFailure('Failed to add company'));
                return;
            }

            dispatch(addCompanySuccess());
        } catch (error: any) {
            dispatch(addCompanyFailure(error.message));
        }
    };
};

// Action creators for updating a company
const updateCompanyRequest = () => ({
    type: UPDATE_COMPANY_REQUEST
});

const updateCompanySuccess = ( ) => ({
    type: UPDATE_COMPANY_SUCCESS,
    
});

const updateCompanyFailure = (error: string) => ({
    type: UPDATE_COMPANY_FAILURE,
    payload: error
});

export const updateCompany = (companyData: Company) => {
    return async (dispatch: any, getState: any) => {
        dispatch(updateCompanyRequest());
        try {
            const result = await CompanyApi.updateCompany(companyData);
            if (!result) {
                dispatch(updateCompanyFailure('Failed to update company'));
                return;
            }

            dispatch(updateCompanySuccess());
        } catch (error: any) {
            dispatch(updateCompanyFailure(error.message));
        }
    };
};

// Action types
export const FETCH_EMPLOYEES_REQUEST = 'FETCH_EMPLOYEES_REQUEST';
export const FETCH_EMPLOYEES_SUCCESS = 'FETCH_EMPLOYEES_SUCCESS';
export const FETCH_EMPLOYEES_FAILURE = 'FETCH_EMPLOYEES_FAILURE';

// Action creators
const fetchEmployeesRequest = () => ({
    type: FETCH_EMPLOYEES_REQUEST
});

const fetchEmployeesSuccess = (employees: CompanyEmployee[]) => ({
    type: FETCH_EMPLOYEES_SUCCESS,
    payload: employees
});

const fetchEmployeesFailure = (error: string) => ({
    type: FETCH_EMPLOYEES_FAILURE,
    payload: error
});

export const getCompanyEmployees = (id: number) => {
    return async (dispatch: any, getState: any) => {
        dispatch(fetchEmployeesRequest());

        try {
            const result = await CompanyApi.getCompanyEmployees(id);

            if (!result) {
                dispatch(fetchEmployeesFailure('Failed to fetch company employees'));
                return;
            }


            const employees = result.data;

            dispatch(fetchEmployeesSuccess(employees));
        } catch (error: any) {
            dispatch(fetchEmployeesFailure(error.message));
        }
    };
};

// Action types
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';

// Action creators
const fetchUsersRequest = () => ({
    type: FETCH_USERS_REQUEST
});

const fetchUsersSuccess = (users: CompanyEmployee[]) => ({
    type: FETCH_USERS_SUCCESS,
    payload: users
});

const fetchUsersFailure = (error: string) => ({
    type: FETCH_USERS_FAILURE,
    payload: error
});

export const getCompanyUsers = (id: number) => {
    return async (dispatch: any, getState: any) => {
        dispatch(fetchUsersRequest());

        try {
            const result = await CompanyApi.getCompanyUsers(id);

            if (!result) {
                dispatch(fetchUsersFailure('Failed to fetch company users'));
                return;
            }

            const users = result.data;
            dispatch(fetchUsersSuccess(users));
        } catch (error: any) {
            dispatch(fetchUsersFailure(error.message));
        }
    };
};
