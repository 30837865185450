
export type Route = {
    readonly path: string;
    readonly displayName: string;
};

/**
 * path - config for router,
 * navigatePath - uses to navigate to page, like:
 * history.push({pathname: `${RoutesConfig.Bookings.navigatePath}/${bookingId}`});
 */

export const RoutesConfig = Object.freeze({
    Home: {
        path: '/',
        displayName: 'Home'
    },
    Users: {
        path: '/users',
        displayName: 'Users'
    },
    UserProfile: {
        path: '/userProfile',
        displayName: 'UserProfile',
    },
    Bookings: {
        path: '/bookings/:fromUrlBookingId?/:backFromDetails?',
        navigatePath: '/bookings',
        displayName: 'Bookings'
    },
    CountryPriceConfig: {
        path: '/countryPriceConfig/(country)?/:fromUrlCountryId?/(isPublished)?/:fromUrlIsPublished?',
        displayName: 'Country price config',
        navigatePath: (fromUrlCountryId?:number, fromUrlIsPublished?:boolean) => {

            let url = `/countryPriceConfig`;

            if (fromUrlCountryId ) {
                url += `/country/${fromUrlCountryId}`;
            }

            if (fromUrlCountryId && fromUrlIsPublished){
                url += `/isPublished/1`;
            }

            return url;
        },
    },
    RegionPriceConfig: {
        path: '/regionPriceConfig/(country)?/:fromUrlCountryId?/(region)?/:fromUrlRegionId?/(isPublished)?/:fromUrlIsPublished?',
        navigatePath: (fromUrlCountryId?:number, fromUrlRegionId?:number, fromUrlIsPublished?:boolean) => {

            let url = `/regionPriceConfig`;

            if (fromUrlCountryId ) {
                url += `/country/${fromUrlCountryId}`;
            }
            if (fromUrlCountryId && fromUrlRegionId){
                url += `/region/${fromUrlRegionId}`;
            }

            if (fromUrlCountryId && fromUrlRegionId && fromUrlIsPublished){
                url += `/isPublished/1`;
            }

            return url;
        },
        displayName: 'Region price config',

    },
    DrivingAreaPriceConfig: {
        path: '/drivingAreaPriceConfig/(country)?/:fromUrlCountryId?/(region)?/:fromUrlRegionId?/(drivingArea)?/:fromUrlDrivingAreaId?/(isPublished)?/:fromUrlIsPublished?',
        navigatePath: (fromUrlCountryId?:number, fromUrlRegionId?:number, fromUrlDrivingAreaId?:number, fromUrlIsPublished?: boolean) => {

            let url = `/drivingAreaPriceConfig`;

            if (fromUrlCountryId ) {
                url += `/country/${fromUrlCountryId}`;
            }
            if (fromUrlCountryId && fromUrlRegionId){
                url += `/region/${fromUrlRegionId}`;
            }
            if (fromUrlCountryId && fromUrlRegionId && fromUrlDrivingAreaId){
                url += `/drivingArea/${fromUrlDrivingAreaId}`;
            }
            if (fromUrlCountryId && fromUrlRegionId && fromUrlDrivingAreaId && fromUrlIsPublished){
                url += `/isPublished/1`;
            }

            return url;
        },
        displayName: 'Driving area price config',

    },
    PriceConfigTest: {
        path: '/priceConfigTest',
        navigatePath: () => {

            let url = '/priceConfigTest';
            return url;
        },
        displayName: 'Driving area price config',

    },
    FinancialReport: {
        path: '/financialReport',
        navigatePath: () => {

            let url = '/financialReport';
            return url;
        },
        displayName: 'Financial report',
    },
    Discount: {
        path: '/discount',
        navigatePath: () => {

            let url = '/discount';
            return url;
        },
        displayName: 'Discount',
    },
    Company: {
        path: '/company',
        displayName: 'Company',
        children: {
            Details: {
                path: '/details/:companyId',
                navigatePath: (companyId: string) => `/company/details/${companyId}`,
                displayName: 'Details',
            },
            Trips: {
                path: '/trips/:companyId',
                navigatePath: (companyId: string) => `/company/trips/${companyId}`,
                displayName: 'Trips',
            },
            Employees: {
                path: '/employees/:companyId',
                navigatePath: (companyId: string) => `/company/employees/${companyId}`,
                displayName: 'Employees',
            },
        },
        navigatePath: () => '/company',
    },


    Login: {
        path: '/login',
        displayName: 'Login',
        loggedIn: false
    },
    ForgotPassword: {
        path: '/fogotPassword',
        displayName: 'Forgot password',
        loggedIn: false
    }

});


export interface BookingsUrlParams { fromUrlBookingId: 'number', backFromDetails?: string};
export interface CountryUrlParam { fromUrlCountryId?: string};
export interface RegionUrlParam { fromUrlRegionId?: string}
export interface DrivingAreaUrlParam { fromUrlDrivingAreaId?: string}
export interface PublishedVersionUrlParam { fromUrlIsPublished?: string}
export interface DiscountsUrlParams { fromUrlDiscountId: 'number', backFromDetails?: string};
